import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './Header.Component';

type HeaderProps = {
    title:any,
    subtitle?:any,
}

const mapStateToProps = (state:any,props:HeaderProps) => {
    return createStructuredSelector<any,any>({

    });
};

const mapDispatchToProps = {
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);