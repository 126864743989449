import React, {ChangeEvent, useEffect, useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import FormRoute from "../../components/formRoute";
import Map from "../../components/map";
import {CheckIn, Route, Travel, Traveler} from "../../types/route";
import {retrieveLanguages} from "../../redux/reducers/language";
import {Language} from "../../types/language";
import moment from "moment";
import L from "leaflet";

const Form:React.FC  = ({retrieveTransportations,
                            routes,
                            newRoute,
                            languages,
                            addCheckIn,
                            removeRoute,
                            checkInIsCompleted,
                            checkInProgress}:any) => {

    const [numRoutes,setStateNumRoute] = useState([{
        id:1
    }]);
    const history = useHistory();
    const [t, i18n] = useTranslation('common');
    const [showMap,setShowMap] = useState(true);
    const [renderMap,setRenderMap] = useState(true);
    const [checkIn,setCheckIn] = useState<CheckIn>({
        travelLegs: [],
        traveler:{
            email:'',
            name:'',
            languageId:0,
        },
        travel:{
            endDate:'',
            startDate:'',
            numBooking:0,
            numPeople:0
        }
    });
    const [error, setError] = useState({
        language: false,
        transportation: false
    });
    const [arrRoutes, setArrRoutes] = useState([]);


    useEffect(() => {
        retrieveTransportations();
        // Show map to paint all of them .After hidden de map
        let timer1 = setTimeout(() => {
            setRenderMap(false)
        }, 100);
        let timer2 = setTimeout(() => {
            setShowMap(false)
        }, 2000);
        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
        };
    }, [retrieveTransportations]);

    useEffect(() => {
        if(!newRoute && routes.length > 0 ){
            setCheckIn(prevState => ({
                ...prevState,
                "travelLegs": routes
            }))
        }

    }, [newRoute, routes]);

    useEffect(() => {

        if(!newRoute && routes.length > 0){
            setRenderMap(false);
            setArrRoutes([]);
            routes.map((route:Route,index:number)=>{
                if(index === 0){
                    let origin:any =  L.latLng( parseFloat(route.origin.longitude), parseFloat(route.origin.latitude) );
                    // @ts-ignore
                    setArrRoutes( prevState =>
                        [
                            ...prevState,
                            origin
                        ]
                    )
                }
                let destination:any =  L.latLng( parseFloat(route.destination.longitude), parseFloat(route.destination.latitude) );
                // @ts-ignore
                setArrRoutes( prevState =>
                    [
                        ...prevState,
                        destination
                    ]
                )
            })
            setTimeout(() => {
                setRenderMap(true);
            }, 400);
        }

    }, [newRoute]);


    useEffect(() => {
        if(languages.length === 0){
            retrieveLanguages();
        }
        languages.map((lng:Language)=>{
            if(lng.languageCode === i18n.language){
                setTraveler("languageId", lng.id);
            }
        })
    }, [languages]);

    useEffect(() => {
        if(!checkInProgress && checkInIsCompleted){
            history.push('/thanks');
        }
    }, [checkInIsCompleted]);

    const handleAddRoute = (e:React.MouseEvent<HTMLElement>) =>{
        e.preventDefault();
        setStateNumRoute(state => [
            ...state,
            {id:numRoutes.length+1}
        ])
    }

    function setTraveler(name: string | number, value:any) {
        const traveler: Traveler = checkIn.traveler;
        // @ts-ignore
        traveler[name] = value;
        setCheckIn(prevState => ({
            ...prevState,
            "traveler": traveler
        }))
    }

    const formChange = (name: string | number, type: any, event: any):void =>{
        const value  = event.target.value;
        if(type === 'traveler'){
            setTraveler(name, value);
            if(name === 'languageId'){
                setError(prevState => ({
                    ...prevState,
                    "language": false
                }))
                languages.map((language:Language)=>{
                    if(language.id === parseInt(value)){
                        i18n.changeLanguage(language.languageCode)
                        localStorage.setItem("language",language.languageCode)
                    }
                })
            }
        }

        if(type === 'travel'){
            const travel: Travel =  checkIn.travel;
            if(name === 'startDate' || name === 'endDate'){
                travel[name] = moment(value).format('DD-MM-YYYYT00:00').toString();
            } else {
                // @ts-ignore
                travel[name] = value;
            }
            setCheckIn(prevState => ({
                ...prevState,
                "travel": travel
            }))
        }
    };

    const handleRemoveRoute = (e:React.MouseEvent<HTMLElement> | ChangeEvent<HTMLSelectElement>) =>{
        e.preventDefault();
        const target = e.target as Element;
        let id:number = parseInt(target.getAttribute('data-remove') as string);
        setStateNumRoute(numRoutes.filter(function(person) {
            return person.id !== id;
        }));
        removeRoute(id-1);
    }

    const handleAddCheckIn = (e: React.FormEvent<HTMLFormElement>):void =>{
        e.preventDefault();
        if(parseInt(String(checkIn.traveler.languageId)) === 0){
            setError(prevState => ({
                ...prevState,
                "language": true
            }))
        }
        checkIn.travelLegs.map((travelLeg)=>{
            if(travelLeg.transportation.id === 0){
                setError(prevState => ({
                    ...prevState,
                    "transportation": true
                }))
            }
        })

        addCheckIn(checkIn)
    }

    return (
        <>
            <div className="bg-background-img-form bg-cover bg-bottom
            w-full flex flex-col justify-items-center content-center bg-background bg-local">
                <header className="App-header w-full flex flex-col">
                    <div className="w-full flex flex-row justify-center content-center self-center mt-10">
                        <h1 className="font-black text-primary text-center title-form">{t('formTravel.title')}</h1>
                    </div>
                </header>
                <div className="w-full flex flex-col justify-items-center content-center items-center mt-10">
                    <form className="w-full flex flex-col" onSubmit={(e)=>{handleAddCheckIn(e)}}>
                        <h2 className="font-black uppercase text-center mb-5">{t('formTravel.person')}</h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 mt-auto self-center content-center gap-4 w-10/12 justify-center">
                            <div className="">
                                <div className="relative">
                                    <select
                                        className="block appearance-none
                                        w-full  py-3 px-4 pr-8
                                        rounded-xl leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="grid-state"
                                        required
                                        value={checkIn.traveler.languageId}
                                        onChange={(e) => {
                                            formChange('languageId','traveler',e);
                                        }}
                                    >
                                        <option value="0" > {t('formTravel.language')} </option>
                                        {languages && languages.length > 0 &&
                                            languages.map((language:Language)=>{
                                                return <option key={'language'+language.id}
                                                        value={language.id}
                                                >
                                                    {language.name}
                                                </option>;

                                            })
                                        }
                                    </select>
                                    <div
                                        className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 20 20">
                                            <path
                                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 md:mb-6">
                                <div className="w-full px-3 md:mb-6 md:mb-0">
                                    <input
                                        className="appearance-none block w-full
                                     border rounded-xl py-3 px-4 mb-3  focus:outline-none focus:bg-white"
                                        id="grid-first-name"
                                        type="text"
                                        required
                                        placeholder={t("formTravel.name")}
                                        onChange={(e) => {
                                            formChange('name', 'traveler', e);
                                        }}/>
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 md:mb-6">
                                <div className="w-full px-3 md:mb-6 md:mb-0">
                                    <input
                                        className="appearance-none block w-full
                                     border rounded-xl py-3 px-4 mb-3  focus:outline-none focus:bg-white"
                                        id="grid-first-name"
                                        type="email"
                                        required
                                        placeholder={t("formTravel.email")}
                                        onChange={(e) => {
                                            formChange('email', 'traveler', e);
                                        }}/>

                                </div>
                            </div>

                        </div>
                        <h2 className="font-black uppercase text-center mb-5"> {t('formTravel.stay')}</h2>
                        <div className="grid  grid-cols-1 md:grid-cols-4 mt-auto self-center content-center gap-4 w-10/12 justify-center">

                            <div className="flex ">
                                <div className="w-full md:px-3 md:mb-6 md:mb-0">
                                    <input
                                        className="appearance-none block w-full
                                     border rounded-xl py-3 px-4 mb-3 focus:outline-none focus:bg-white"
                                        id="numBooking"
                                        type="text"
                                        name="numBooking"
                                        required
                                        autoComplete="off"
                                        placeholder={t("formTravel.booking")}
                                        onChange={(e) => {
                                            formChange('numBooking', 'travel', e);
                                        }}/>
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 md:mb-6">
                                <div className="w-full px-3 md:mb-6 md:mb-0">
                                    <input
                                        className="appearance-none block w-full
                                     border rounded-xl py-3 px-4 mb-3  focus:outline-none focus:bg-white"
                                        id="numPeople"
                                        type="number"
                                        name="numPeople"
                                        required
                                        autoComplete="off"
                                        placeholder={t("formTravel.numberPersons")}
                                        onChange={(e) => {
                                        formChange('numPeople', 'travel', e);
                                    }}/>
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 md:mb-6">
                                <div className="w-full px-3 md:mb-6 md:mb-0">
                                    <input
                                        className="appearance-none block w-full
                                     border rounded-xl py-3 px-4 mb-3  focus:outline-none focus:bg-white"
                                        id="startDate"
                                        name="startDate"
                                        autoComplete="off"
                                        type="text"
                                        required
                                        onFocus={(e) => e.target.type = 'date'}
                                        placeholder={t("formTravel.entry")}
                                        onChange={(e) => {
                                        formChange('startDate', 'travel', e);
                                    }}/>
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 md:mb-6">
                                <div className="w-full px-3 md:mb-6 md:mb-0">
                                    <input
                                        className="appearance-none block w-full
                                     border rounded-xl py-3 px-4 mb-3  focus:outline-none focus:bg-white"
                                        id="endDate"
                                        name="endDate"
                                        type="text"
                                        onFocus={(e) => e.target.type = 'date'}
                                        autoComplete="off"
                                        required
                                        placeholder={t("formTravel.departure")}
                                        onChange={(e) => {
                                            formChange('endDate', 'travel', e);
                                        }}/>
                                </div>
                            </div>
                        </div>
                        <h2 className="font-black uppercase text-center mb-5">{t('formTravel.route')}</h2>
                        <div className="flex flex-row mt-auto gap-4 w-10/12 self-center">
                            <div className="flex-grow grid grid-cols-1 md:grid-cols-3 mt-auto self-center content-center gap-4 w-full justify-center">
                                {
                                    numRoutes && numRoutes.map((item,index)=>{
                                        const origin = index > 0 && checkIn.travelLegs[index-1]  && checkIn.travelLegs[index-1].destination ?
                                                        checkIn.travelLegs[index-1].destination : {}
                                        return  <FormRoute key={item.id+"_formRoute"}
                                                           id={item.id}
                                                           origin={origin}
                                        />
                                    })
                                }
                            </div>
                            <div className="flex-none grid grid-cols-1 gap-2 justify-center">
                                {
                                    numRoutes && numRoutes.map((item)=>{
                                        const onClickFunction = item.id === 1 ? handleAddRoute : handleRemoveRoute;
                                        const text = item.id === 1 ? '+' : '-';
                                        return <>
                                            <div key={item.id+"_numRoutes"}
                                                 data-remove={item.id}
                                                 className="flex justify-center cursor-pointer items-center more-transport "
                                                 onClick={onClickFunction}>
                                                {text}
                                            </div>
                                        </>

                                    })
                                }
                            </div>
                        </div>
                        <div className="flex flex-row w-10/12 justify-center mt-2 mb-2">
                            <p className="ml-auto text-primary cursor-pointer"
                                onClick={()=>{
                                    setShowMap(!showMap);
                                    setRenderMap(!showMap);
                                }}
                            >
                                {t('formTravel.map')}
                            </p>
                            <div
                                className={"pointer-events-none right-0 flex items-center px-2 text-primary " +
                                `${showMap ? "rotate-180" : ""}`}>
                                <svg className="fill-current h-4 w-4 " xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                </svg>
                            </div>
                        </div>
                        {renderMap && showMap &&
                            <div className={"flex flex-col w-full justify-center mt-10 " +
                            `${showMap ? " " : " hidden"}`}>
                                <Map routes={arrRoutes}/>
                            </div>
                        }

                        <div className="w-96 flex flex-col self-center justify-center mt-auto ">
                            <div className="error flex p-4  flex flex-col self-center">
                                {
                                    (error.language) &&
                                    <p className="text-3xl font-bold">{t("error.language")}</p>
                                }
                                {
                                    (error.transportation) &&
                                    <p className="text-xl font-bold">{t("error.transportation")}</p>
                                }
                            </div>
                            <button
                                type="submit"
                                className="w-full mx-4 text-center self-center justify-center
                                            flex flex-row bg-black rounded-2xl px-28	py-5"
                            >
                                <h3 className="text-secondary font-black self-center uppercase">
                                    {t('formTravel.submit')}
                                </h3>
                            </button>
                        </div>
                    </form>
                </div>


                <div className="w-full flex self-end justify-center mt-auto mt-5 mb-5">
                        <h2 className="text-black font-black self-center sm:text-center md:tex-left">
                            {t('formTravel.emissions')}
                        </h2>
                </div>
                <div className="w-full flex self-end justify-center mt-auto mb-5">
                        <Link to="/">
                            <p className="text-black underline text-2xl	 self-center">
                                {t('formTravel.back')}
                            </p>
                        </Link>

                </div>
            </div>
        </>
    )

}

export default withTranslation('common')(Form);