import { createSelector } from 'reselect'
import {AppState} from "../store/store";
import {Dispatch} from "redux";
import {Route} from "../../types/route";

export const ACTIONS = {
    INIT_ROUTE: 'INIT_ROUTE',
    ADD_ROUTE_REQUEST: 'ADD_ROUTE_REQUEST',
    ADD_ROUTE_SUCCESS: 'ADD_ROUTE_SUCCESS',
    ADD_ROUTE_FAILURE: 'ADD_ROUTE_FAILURE',
    REMOVE_ROUTE_REQUEST: 'REMOVE_ROUTE_REQUEST',
    REMOVE_ROUTE_SUCCESS: 'REMOVE_ROUTE_SUCCESS',
    REMOVE_ROUTE_FAILURE: 'REMOVE_ROUTE_FAILURE',
};

const InitialState = {
    routes : [] as Route[],
    inProgress : false,
    id: 0,
    error: ''
}

const getSlice = (state: AppState) => state.route;

export const getRoutes = createSelector(
    getSlice,
    ({routes}) => routes,
);

export const getNewRoute = createSelector(
    getSlice,
    ({inProgress}) => inProgress,
);

export const initRouteAction = (): RouteActions => ({
    type :ACTIONS.INIT_ROUTE,
})


export const addInProgressRoute = (): RouteActions => ({
    type :ACTIONS.ADD_ROUTE_REQUEST,
})

export const addRouteSuccess = (route:Route): RouteActions => ({
    type:ACTIONS.ADD_ROUTE_SUCCESS,
    route
})

export const addRouteFailure = (error:string): RouteActions => ({
    type :ACTIONS.ADD_ROUTE_FAILURE,
    error
})

export const removeInProgressRoute = (): RouteActions => ({
    type :ACTIONS.REMOVE_ROUTE_REQUEST,
})

export const removeRouteSuccess = (id:number): RouteActions => ({
    type:ACTIONS.REMOVE_ROUTE_SUCCESS,
    id
})

export const removeRouteFailure = (error:string): RouteActions => ({
    type :ACTIONS.REMOVE_ROUTE_FAILURE,
    error
})

export interface RouteInitAction {
    type: typeof ACTIONS.INIT_ROUTE
}

export interface RouteAddRequestAction {
    type: typeof ACTIONS.ADD_ROUTE_REQUEST
}

export interface RouteAddSuccessAction {
    type: typeof ACTIONS.ADD_ROUTE_SUCCESS,
    route?: Route
}

export interface RouteAddFailureAction {
    type: typeof ACTIONS.ADD_ROUTE_FAILURE,
    error: string
}

export interface RouteRemoveRequestAction {
    type: typeof ACTIONS.REMOVE_ROUTE_REQUEST
}

export interface RouteRemoveSuccessAction {
    type: typeof ACTIONS.REMOVE_ROUTE_SUCCESS,
    id?: number
}

export interface RouteRemoveFailureAction {
    type: typeof ACTIONS.REMOVE_ROUTE_FAILURE,
    error: string
}


export type RouteActions =
    | RouteAddRequestAction
    | RouteAddSuccessAction
    | RouteAddFailureAction
    | RouteInitAction
    | RouteRemoveFailureAction
    | RouteRemoveSuccessAction
    | RouteRemoveRequestAction
    ;

export const initRoute = (routeData: Route) => {
    return async (dispatch: Dispatch<RouteActions>) => {
        dispatch(initRouteAction())
    }
}

export const removeRoute = (id: number) => {
    return async (dispatch: Dispatch<RouteActions>) => {
        dispatch(removeInProgressRoute())
        try {
            setTimeout(() => {
                dispatch(removeRouteSuccess(id));
            }, 500)
        } catch (error:any) {
            dispatch(removeRouteFailure(error))
        }
    }
}

export const addRoute = (routeData: Route) => {
    return async (dispatch: Dispatch<RouteActions>) => {
        dispatch(addInProgressRoute())
        try {
            setTimeout(() => {
                dispatch(addRouteSuccess(routeData));
            }, 500)
        } catch (error:any) {
            dispatch(addRouteFailure(error))
        }
    }
}



const routeReducer = (state = InitialState, action: RouteActions):
                      {   inProgress: boolean,
                          error: string,
                          id: number,
                          routes:Route[], } => {
    let routes = state.routes;
    switch (action.type) {
        case ACTIONS.INIT_ROUTE:
            routes = [];
            return {
                ...state,
                inProgress : false,
                error: '',
                routes : routes
            };
        case ACTIONS.ADD_ROUTE_REQUEST:
        case ACTIONS.REMOVE_ROUTE_REQUEST:
            return {
                ...state,
                inProgress : true,
                error: ''
            };
        case ACTIONS.ADD_ROUTE_SUCCESS:
            // @ts-ignore
            routes[action.route.id] = action.route;
            return {
                ...state,
                inProgress : false,
                routes : routes
            };
        case ACTIONS.REMOVE_ROUTE_SUCCESS:

            for( var i = 0; i < routes.length; i++){
                // @ts-ignore
                if ( routes[i].id === action.id) {
                    if(routes[i+1]){
                        routes[i+1].origin = routes[i-1].destination;
                    }
                    routes.splice(i, 1);
                }
            }

            return {
                ...state,
                inProgress : false,
                routes : routes
            };
        case ACTIONS.ADD_ROUTE_FAILURE:
        case ACTIONS.REMOVE_ROUTE_FAILURE:
            return {
                ...state,
                inProgress : false,
                error: "error" in action ? action.error : ''
            };
        default:
            return state;
    }
};



export {routeReducer};