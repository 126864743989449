import React, { useEffect, useState } from "react";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const ButtonCalcula:React.FC  = ({logo, classTextColor,t}:any) => {


        return (
            <>
                <Link to="/form" className="w-full flex-auto button-calcula flex self-end justify-center mt-auto mb-10">
                    <div className="w-full flex self-end justify-center mt-auto mb-10">
                        <button className="flex flex-row bg-black rounded-xl px-14	py-3 tracking-widest	">
                            {
                                logo &&
                                <img src={logo} className="h-10 mr-5" alt=""/>
                            }
                            <h3 className={ classTextColor + " self-center uppercase font-black "}>
                                Calcula la teva petjada
                            </h3>
                        </button>
                    </div>
                </Link>
            </>
        )

}


export default withTranslation('common')(ButtonCalcula);
