import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay'
import Home from "../home";
import Comparation from "../comparation";
import JAM from "../jam";
import Project from "../project";
import Charts from "../charts";
import Navigation from "../../components/navigation";

const AutoplaySlider = withAutoplay(AwesomeSlider)
const Slide: React.FC = ({t}: any) => {
    useEffect(() => {
    }, []);

    return (
        <>

            <AutoplaySlider
                fillParent={true}
                play={true}
                bullets={false}
                organicArrows={false}
                cancelOnInteraction={false} // should stop playing on user interaction
                interval={50000}>
                <div className="w-screen w-fit h-screen">
                    <Navigation/>
                    <Home/>
                </div>
                <div className="w-screen w-fit h-screen">
                    <Navigation/>
                    <Comparation/>
                </div>
                <div className="w-screen w-fit h-screen">
                    <Navigation/>
                    <JAM/>
                </div>
                <div className="w-screen w-fit h-screen">
                    <Navigation/>
                    <Charts/>
                </div>
                <div className="w-screen w-fit h-screen">
                    <Navigation/>
                    <Project/>
                </div>
            </AutoplaySlider>
        </>
    )

}

export default withTranslation('common')(Slide);
