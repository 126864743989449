import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './FormRoute.Component';
import {addRoute, getNewRoute, getRoutes} from "../../redux/reducers/route";
import {getTransportations} from "../../redux/reducers/transportation";

type FormRouteProps = {
    id: number
    origin?: any;
}

const mapStateToProps = (state:any,props:FormRouteProps) => {
    return createStructuredSelector<any,any>({
        transportations : getTransportations,
        routes: getRoutes
    });
};

const mapDispatchToProps = {
    addRoute
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);