import { createSelector } from 'reselect'
import {AppState} from "../store/store";
import {Dispatch} from "redux";
import {Slide} from "../../types/slides";
import {getAllSlides} from "../../services/slides";

export const ACTIONS = {
    QUERY_SLIDE_REQUEST: 'QUERY_SLIDE_REQUEST',
    QUERY_SLIDE_SUCCESS: 'QUERY_SLIDE_SUCCESS',
    QUERY_SLIDE_FAILURE: 'QUERY_SLIDE_FAILURE',
};

const InitialState = {
    slides : [] as Slide[],
    inProgress : false,
    error: ''
}

const getSlice = (state: AppState) => state.slides;

export const getSlides = createSelector(
    getSlice,
    ({slides}) => slides,
);

export const setInProgress = (): SlidesActions => ({
    type :ACTIONS.QUERY_SLIDE_REQUEST,
})

export const setSlidesSuccess = (slides:Slide[]): SlidesActions => ({
    type:ACTIONS.QUERY_SLIDE_SUCCESS,
    slides
})

export const setSlidesFailure = (error:string): SlidesActions => ({
    type :ACTIONS.QUERY_SLIDE_FAILURE,
    error
})


export interface SlideRequestAction {
    type: typeof ACTIONS.QUERY_SLIDE_REQUEST
}

export interface SlideSuccessAction {
    type: typeof ACTIONS.QUERY_SLIDE_SUCCESS,
    slides : Slide[]
}

export interface SlideFailureAction {
    type: typeof ACTIONS.QUERY_SLIDE_FAILURE,
    error : string
}


export type SlidesActions =
    SlideRequestAction
    | SlideSuccessAction
    | SlideFailureAction
    ;


export const retrieveSlides = () => {
    return async (dispatch: Dispatch<SlidesActions>) => {
        dispatch(setInProgress())
        try {
            const response = await getAllSlides();
            dispatch(setSlidesSuccess(response.data.response));
        } catch (error:any) {
            dispatch(setSlidesFailure(error))
        }
    }
}

const slidesReducer = (state = InitialState, action: SlidesActions):
    {   inProgress: boolean,
        error: string,
        slides:Slide[], } => {
    switch (action.type) {
        case ACTIONS.QUERY_SLIDE_REQUEST:
            return {
                ...state,
                inProgress : true,
                error: ''
            };
        case ACTIONS.QUERY_SLIDE_SUCCESS:
            return {
                ...state,
                inProgress : false,
                slides: "slides" in action ? action.slides : []
            };
        case ACTIONS.QUERY_SLIDE_FAILURE:
            return {
                ...state,
                inProgress : false,
                error: "error" in action ? action.error : ''
            };
        default:
            return state;
    }
};



export {slidesReducer};