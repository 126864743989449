import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './Thanks.Component';


const mapStateToProps = createStructuredSelector<any,any>({

});

const mapDispatchToProps = {

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);