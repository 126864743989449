import React, {useEffect, useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import lamp from "../../assets/images/lamp.png";
import trash from "../../assets/images/trash.png";
import airplane from "../../assets/images/airplane.png";
import water from "../../assets/images/water.png";
import FigureConsum from "../../components/figureConsum";
import ButtonCalcula from "../../components/buttonCalcula";
import Header from "../../components/header";
import logo from "../../assets/images/logo-light.png";
import {getCards, getText} from "../../services/functions";

const JAM: React.FC = ({slides}: any) => {

    const [t, i18n] = useTranslation('common');
    const [title, setTitle] = useState('');
    const [boldLamp, setBoldLamp] = useState('');
    const [regularLamp, setRegularLamp] = useState('');
    const [boldWater, setBoldWater] = useState('');
    const [regularWater, setRegularWater] = useState('');
    const [boldWaste, setBoldWaste] = useState('');
    const [regularWaste, setRegularWaste] = useState('');
    const [boldTre, setBoldTre] = useState('');
    const [regularTre, setRegularTre] = useState('');
    const [subTitle, setSubTitle] = useState('');

    useEffect(() => {
        getText(slides, 3, i18n, setTitle, setBoldLamp, setRegularLamp,setSubTitle);
        getCards(slides, 3,1, i18n,setBoldLamp,setRegularLamp);
        getCards(slides, 3,2, i18n,setBoldWater,setRegularWater);
        getCards(slides, 3,3, i18n,setBoldWaste,setRegularWaste);
        getCards(slides, 3,4, i18n,setBoldTre,setRegularTre);
    }, [slides,t]);


    useEffect(() => {
    }, []);

    return (
        <>
            <div className="bg-background-img-consum-2 bg-cover bg-bottom
                w-full h-screen flex flex-col justify-items-center content-center bg-background bg-local">
                <Header title={title}
                        subtitle={subTitle}
                />
                <div className="flex-auto grid lg:p-4 grid-cols-4 justify-items-center content-center mb-10
                                self-center gap-4 w-full">
                    <FigureConsum logo={lamp}
                                  bold={"<p><b>"+boldLamp+"</b><p>"}
                                  description={"<p>"+regularLamp+"</p>"} />
                    <FigureConsum logo={water}
                                  bold={"<p><b>"+boldWater+"</b><p>"}
                                  description={"<p>"+regularWater+"</p>"} />

                    <FigureConsum logo={trash}
                                  bold={"<p><b>"+boldWaste+"</b><p>"}
                                  description={"<p>"+regularWaste+"</p>"} />
                    <FigureConsum logo={airplane}
                                  bold={"<p><b>"+boldTre+"</b><p>"}
                                  description={"<p>"+regularTre+"</p>"} />
                </div>
                <ButtonCalcula logo={logo} classTextColor="text-secondary" />
            </div>
        </>
    )

}

export default withTranslation('common')(JAM);
