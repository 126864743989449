import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './FigureLineChart.Component';
import {getElectricity, getTraveRelatedEmission, getWaste, getWater} from "../../redux/reducers/consumptionResource";

type FigureConsumProps = {
    logo?:any,
    backgroundColor?: any,
    borderColor?: any,
    circleColor?:any,
    number?: any,
    SI?: any,
}

const mapStateToProps = (state:any,props:FigureConsumProps) => {
    return createStructuredSelector<any,any>({
        electricity: getElectricity,
        water: getWater,
        waste: getWaste,
        tre: getTraveRelatedEmission,
    });
};

const mapDispatchToProps = {
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);