import {Slide} from "../types/slides";

export function getText(slides: Slide[],
                        id: number,
                        lng: any,
                        setTitle: (value: (((prevState: string) => string) | string)) => void,
                        setBold?: (value: (((prevState: string) => string) | string)) => void,
                        setRegular?: (value: (((prevState: string) => string) | string)) => void,
                        setSubTitle?: (value: (((prevState: string) => string) | string)) => void,
                        setSuperTitle?: (value: (((prevState: string) => string) | string)) => void
) {
    const slideById = slides.find((item: Slide) => {
        return item.id === id;
    });
    if (slideById && slideById.translationsSlides.length > 0) {
        slideById.translationsSlides.forEach((item: any) => {
            if (item.fieldName === 'title' && item.language === lng.language) {
                setTitle(item.text)
            }
            if (item.fieldName === 'supertitle'  && item.language === lng.language) {
                if (setSuperTitle) {
                    setSuperTitle(item.text)
                }
            }
            if (item.fieldName === 'bold' && item.language === lng.language) {
                if (setBold) {
                    setBold(item.text)
                }
            }
            if (item.fieldName === 'regular' && item.language === lng.language) {
                if (setRegular) {
                    setRegular(item.text)
                }
            }
            if (item.fieldName === 'subtitle' && item.language === lng.language) {
                if (setSubTitle) {
                    setSubTitle(item.text)
                }
            }
        })
    }
}


export function getCards(slides: Slide[],
                         idSlide: number,
                         idCard: number,
                         lng: any,
                         setBold?: (value: (((prevState: string) => string) | string)) => void,
                         setRegular?: (value: (((prevState: string) => string) | string)) => void,
                         setImg?: (value: (((prevState: string) => string) | string)) => void,
) {
    const slideById = slides.find((item: Slide) => {
        return item.id === idSlide;
    });

    if (slideById) {
        const CardById = slideById.cards.find((item) => {
            return item.id === idCard
        })
        if (CardById) {
            if(setImg){
                setImg(CardById.imageUrl);
            }
            CardById.translationsCards.forEach((item: any) => {
                if (item.fieldName === 'bold' && item.language === lng.language) {
                    if (setBold) {
                        setBold(item.text)
                    }
                }
                if (item.fieldName === 'regular' && item.language === lng.language) {
                    if (setRegular) {
                        setRegular(item.text)
                    }
                }
                if (item.fieldName === 'regular' && item.language === lng.language) {
                    if (setRegular) {
                        setRegular(item.text)
                    }
                }
            })
        }
    }
}
