import React, {useEffect, useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import project1 from "../../assets/images/project1.png";
import project2 from "../../assets/images/project2.png";
import project3 from "../../assets/images/project3.png";
import project4 from "../../assets/images/project4.png";
import FigureProject from "../../components/figureProject";
import ButtonCalcula from "../../components/buttonCalcula";
import Header from "../../components/header";
import logo from "../../assets/images/logo-light.png";
import {getCards, getText} from "../../services/functions";

const Project:React.FC  = ({slides}:any) => {

    const [t, i18n] = useTranslation('common');
    const [title, setTitle] = useState('');
    const [boldLamp, setBoldLamp] = useState('');
    const [regularLamp, setRegularLamp] = useState('');
    const [imgLamp, setImgLamp] = useState('');
    const [boldWater, setBoldWater] = useState('');
    const [regularWater, setRegularWater] = useState('');
    const [imgWater, setImgWater] = useState('');
    const [boldWaste, setBoldWaste] = useState('');
    const [regularWaste, setRegularWaste] = useState('');
    const [imgWaste, setImgWaste] = useState('');
    const [boldTre, setBoldTre] = useState('');
    const [regularTre, setRegularTre] = useState('');
    const [imgTre, setImgTre] = useState('');
    const [subTitle, setSubTitle] = useState('');

    useEffect(() => {
        getText(slides, 5, i18n, setTitle, setBoldLamp, setRegularLamp,setSubTitle);
        getCards(slides, 5,5, i18n,setBoldLamp,setRegularLamp,setImgLamp);
        getCards(slides, 5,6, i18n,setBoldWater,setRegularWater,setImgWater);
        getCards(slides, 5,7, i18n,setBoldWaste,setRegularWaste,setImgWaste);
        getCards(slides, 5,8, i18n,setBoldTre,setRegularTre,setImgTre);
    }, [slides,t]);


    return (
        <>
            {/*ACCIONS AL JAM*/}
            <div className="w-full h-screen flex flex-col justify-items-center content-center bg-background bg-local">
                <Header title={title}
                        subtitle={subTitle}
                />
                <div className="flex-auto grid grid-cols-2  h-screen justify-center justify-items-center content-center px-2
                                self-center gap-5 project-grid w-full">
                    <FigureProject logo={imgLamp ? imgLamp : project1}
                                   bold={"<b>"+boldLamp+"</b>"}
                                   description={regularLamp}/>
                    <FigureProject logo={imgWater ? imgWater : project2}
                                   bold={"<b>"+boldWater+"</b>"}
                                   description={regularWater}/>
                    <FigureProject logo={imgWaste ? imgWaste : project3}
                                   bold={"<b>"+boldWaste+"</b>"}
                                   description={regularWaste}
                                   order='change'/>
                    <FigureProject logo={imgTre ? imgTre : project4}
                                   bold={"<b>"+boldTre+"</b>"}
                                   description={regularTre}
                                   order="change"/>
                </div>
                <ButtonCalcula logo={logo} classTextColor="text-secondary" />
            </div>
        </>
    )

}

export default withTranslation('common')(Project);
