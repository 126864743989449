import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './Comparation.Component';
import {
    getElectricityAvg,
    getTraveRelatedEmissionAvg,
    getWasteAvg,
    getWaterAvg
} from "../../redux/reducers/consumptionResource";
import {getSlides} from "../../redux/reducers/slides";


const mapStateToProps = createStructuredSelector<any,any>({
    electricityAvg: getElectricityAvg,
    waterAvg: getWaterAvg,
    wasteAvg: getWasteAvg,
    treAvg: getTraveRelatedEmissionAvg,
    slides: getSlides
});

const mapDispatchToProps = {
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);