import api from "./api";

export async function getAllElectricity(startDate:any,endDate:any) {
    const response = await api.get("/resource/electricity?startDate="+startDate+"&endDate="+endDate);

    return response;
}

export async function getElectricityAverageByType(startDate:any,endDate:any) {
    const response = await api.get("/resource/electricity/average?startDate="+startDate+"&endDate="+endDate);
    return response;
}

export async function getAllWater(startDate:any,endDate:any) {
    const response = await api.get("/resource/water?startDate="+startDate+"&endDate="+endDate);
    return response;
}

export async function getWaterAverageByType(startDate:any,endDate:any) {
    const response = await api.get("/resource/water/average?startDate="+startDate+"&endDate="+endDate);
    return response;
}


export async function getAllWaste(startDate:any,endDate:any) {
    const response = await api.get("/resource/waste?startDate="+startDate+"&endDate="+endDate);
    return response;
}

export async function getWasteAverageByType(startDate:any,endDate:any) {
    const response = await api.get("/resource/waste/average?startDate="+startDate+"&endDate="+endDate);
    return response;
}

export async function getAllTravelRelatedEmission(limit:any) {
    const response = await api.get("/resource/travel-related-emission?limit="+limit);
    return response;
}

export async function getTravelRelatedEmissionAverageByType(limit:any) {
    const response = await api.get("/resource/travel-related-emission/average?limit="+limit);
    return response;
}