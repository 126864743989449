import React from "react";
import {withTranslation} from "react-i18next";
import {MapContainer, TileLayer} from "react-leaflet";
import Routing from "../routing";
import {LatLngExpression} from "leaflet";
import "leaflet/dist/leaflet.css";

export interface MapType {
    lat: number;
    lng: number;
    zoom : number;
    isMapInit : boolean;
}

const Map:React.FC  = ({routes}:any) => {
    const position:LatLngExpression = [57.74, 11.94];
    return (
            <>
                <MapContainer center={position} zoom={13} zoomControl={false} style={{ height: "500px", width:"100%" }}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Routing routes={routes} />
                </MapContainer>
            </>
        )
}

export default withTranslation('common')(Map);