import { createSelector } from 'reselect'
import {AppState} from "../store/store";
import {Dispatch} from "redux";
import {getAllLanguages} from "../../services/language";
import {Language} from "../../types/language";

export const ACTIONS = {
    QUERY_LANGUAGE_REQUEST: 'QUERY_LANGUAGE_REQUEST',
    QUERY_LANGUAGE_SUCCESS: 'QUERY_LANGUAGE_SUCCESS',
    QUERY_LANGUAGE_FAILURE: 'QUERY_LANGUAGE_FAILURE',
};

const InitialState = {
    languages : [] as Language[],
    inProgress : false,
    error: ''
}

const getSlice = (state: AppState) => state.languages;

export const getLanguages = createSelector(
    getSlice,
    ({languages}) => languages,
);

export const getNewLanguages = createSelector(
    getSlice,
    ({inProgress}) => inProgress,
);

export const setInProgress = (): LanguagesActions => ({
    type :ACTIONS.QUERY_LANGUAGE_REQUEST,
})

export const setLanguagesSuccess = (languages:Language[]): LanguagesActions => ({
    type:ACTIONS.QUERY_LANGUAGE_SUCCESS,
    languages
})

export const setLanguagesFailure = (error:string): LanguagesActions => ({
    type :ACTIONS.QUERY_LANGUAGE_FAILURE,
    error
})


export interface LanguageRequestAction {
    type: typeof ACTIONS.QUERY_LANGUAGE_REQUEST
}

export interface LanguageSuccessAction {
    type: typeof ACTIONS.QUERY_LANGUAGE_SUCCESS,
    languages : Language[]
}

export interface LanguageFailureAction {
    type: typeof ACTIONS.QUERY_LANGUAGE_FAILURE,
    error : string
}


export type LanguagesActions =
    LanguageRequestAction
    | LanguageSuccessAction
    | LanguageFailureAction
    ;


export const retrieveLanguages = () => {
    return async (dispatch: Dispatch<LanguagesActions>) => {
        dispatch(setInProgress())
        try {
            const response = await getAllLanguages();
            dispatch(setLanguagesSuccess(response.data.response));
        } catch (error:any) {
            dispatch(setLanguagesFailure(error))
        }
    }
}

const languagesReducer = (state = InitialState, action: LanguagesActions):
    {   inProgress: boolean,
        error: string,
        languages:Language[], } => {
    switch (action.type) {
        case ACTIONS.QUERY_LANGUAGE_REQUEST:
            return {
                ...state,
                inProgress : true,
                error: ''
            };
        case ACTIONS.QUERY_LANGUAGE_SUCCESS:
            return {
                ...state,
                inProgress : false,
                languages: "languages" in action ? action.languages : []
            };
        case ACTIONS.QUERY_LANGUAGE_FAILURE:
            return {
                ...state,
                inProgress : false,
                error: "error" in action ? action.error : ''
            };
        default:
            return state;
    }
};



export {languagesReducer};