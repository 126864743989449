import { combineReducers } from "redux";
import {routeReducer} from "./route";
import {transportationsReducer} from "./transportation";
import {consumptionResourceReducer} from "./consumptionResource";
import {slidesReducer} from "./slides";
import {languagesReducer} from "./language";
import {checkInReducer} from "./checkIn";

const rootReducer = combineReducers({
    route: routeReducer,
    transportations: transportationsReducer,
    consumptionResource: consumptionResourceReducer,
    languages: languagesReducer,
    slides: slidesReducer,
    checkIn: checkInReducer
});

export default rootReducer;