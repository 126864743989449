import React, {useEffect, useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import logo from "../../assets/images/logo.png";
import basa from "../../assets/images/basa.png";
import info2c from "../../assets/images/info2c.png";
import {getText} from "../../services/functions";


const Home:React.FC  = ({slides}:any) => {
    const [t, i18n] = useTranslation('common');
    const [title, setTitle] = useState('');
    const [superTitle, setSuperTitle] = useState('');
    const [bold, setBold] = useState('');
    const [regular, setRegular] = useState('');
    const [subTitle, setSubTitle] = useState('');
    console.log(slides);
    useEffect(() => {
        getText(slides, 1, i18n, setTitle, setBold, setRegular, setSubTitle, setSuperTitle);
    }, [slides,t]);

    return (
        <>
            <div className="bg-background-img h-screen bg-cover bg-bottom
            w-full h-screen flex flex-col justify-items-center content-center bg-background bg-local">
                <header className="App-header w-full flex flex-col">
                    <div className="w-full flex flex-row justify-center content-center self-center xs:mt-20">
                        <img className="mt-10" src={logo} alt=""/>
                        <h2 className="text-bold -ml-24 self-end">{superTitle}</h2>
                    </div>
                    <h1 className="mt-2 text-center uppercase font-black ">{title}</h1>
                    <p className="flex flex-col self-center text-center w-full md:w-4/5	justify-center
                                  content-center items-center text-xl lg:text-2xl mt-8">
                        <strong>{bold}</strong>
                        {regular}
                    </p>
                </header>
                <div className="w-full flex flex-row justify-center content-center self-center mt-auto mb-20">
                    <img className="lg:mt-10 ml-10" src={basa} width="300px" alt=""/>
                    <img className="lg:mt-10 ml-40" src={info2c} width="200px" alt=""/>
                </div>
            </div>
        </>
    )

}

export default withTranslation('common')(Home);
