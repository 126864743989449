import React, {useEffect, useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import lamp from "../../assets/images/lamp.png";
import water from "../../assets/images/water.png";
import trash from "../../assets/images/trash.png";
import airplane from "../../assets/images/airplane.png";
import ButtonCalcula from "../../components/buttonCalcula";
import Header from "../../components/header";
import FigureLineChart from "../../components/figureLineChart";
import logo from "../../assets/images/logo-light.png";
import {getText} from "../../services/functions";

const Charts:React.FC  = ({slides,electricityAvg,waterAvg,wasteAvg,treAvg}:any) => {

    const [t, i18n] = useTranslation('common');
    const [title, setTitle] = useState('');
    const [bold, setBold] = useState('');
    const [regular, setRegular] = useState('');
    const [subTitle, setSubTitle] = useState('');

    useEffect(() => {
        getText(slides, 4, i18n, setTitle, setBold, setRegular,setSubTitle);
    }, [slides,t]);

    return (
        <>
            <div className="w-full h-screen flex flex-col gap-y-4 justify-items-center content-center bg-background bg-local">
                <Header title={title}
                        subtitle={subTitle}
                />
                <div className="flex-auto flex flex-wrap w-full justify-center justify-items-center content-start
                                gap-2 w-full chart-height">
                    <FigureLineChart logo={lamp}
                                     number={electricityAvg}
                                     SI={'Wh'}
                                     circleColor={'circle-yellow'}
                                     backgroundColor="rgb(253,249,205)"
                                     borderColor="rgb(237,229,88)"/>
                    <FigureLineChart logo={water}
                                     number={waterAvg}
                                     SI={'l/min'}
                                     circleColor={'circle-blue'}
                                     backgroundColor="rgb(194,207,217)"
                                     borderColor="rgb(44,93,192)"/>
                    <FigureLineChart logo={trash}
                                     number={wasteAvg}
                                     SI={'kg'}
                                     circleColor={'circle-brown'}
                                     backgroundColor="rgb(219,212,193)"
                                     borderColor="rgb(147,119,84)"/>
                    <FigureLineChart logo={airplane}
                                     number={treAvg}
                                     SI={'co2 kg'}
                                     circleColor={'circle-black'}
                                     backgroundColor="rgb(213,210,198)"
                                     borderColor="rgb(114,112,106)"/>
                </div>
                <ButtonCalcula logo={logo} classTextColor="text-secondary" />
            </div>
        </>
    )

}

export default withTranslation('common')(Charts);
