import React, { useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import {Language} from "../../types/language";

const NavigationContainer:React.FC  = ({languages}:any) => {

    const [t, i18n] = useTranslation('common');
    const [showLanguage, setShowLanguage] = useState(false)
    let menu

    if (showLanguage) {
        menu =<div className="flex flex-row">
            <div id="navigation-landing"
                 className="relative border-0 flex flex-col justify-center item-center text-center w-full h-auto z-50 ">
                <ul className="text-3xl mt-auto">
                    {languages && languages.length > 0 &&
                        languages.map((language:Language)=>{
                            return <li key={"lan_"+language.id}
                                       className="flex justify-center mb-2 "
                                onClick={() => {
                                i18n.changeLanguage(language.languageCode)
                                setShowLanguage(false)
                                localStorage.setItem("language",language.languageCode)
                                }}>
                                <p>{language.languageCode}</p>
                            </li>
                        })
                    }
                </ul>
            </div>
        </div> ;
    }
    return (
        <nav id="menu-component" className="z-50 absolute top-2 right-2  p-4">
            <div onClick={() => setShowLanguage(!showLanguage)}
                 className="cursor-pointer language border-2  rounded-full  hover:border-gray-500 p-4">
                {i18n.language}
            </div>

            {menu}
        </nav>
    )

}

export default withTranslation('common')(NavigationContainer);