import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './Routing.Component';

type MapProps = {
   routes: any;
}

const mapStateToProps = (state:any,props:MapProps) => {
    return createStructuredSelector<any,any>({
    });
};

const mapDispatchToProps = {

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);