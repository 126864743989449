import L from "leaflet";
import "leaflet-routing-machine";
import React, {useEffect, useState} from "react";
import { useMap } from "react-leaflet";
import {withTranslation} from "react-i18next";


L.Marker.prototype.options.icon = L.icon({
    iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png"
});

const Routing: React.FC = ({routes,t}: any) => {
    const map = useMap();

    useEffect(() => {
        if(map){
            if (routes.length > 0 ) {
                L.Routing.control({
                    waypoints: routes,
                    routeWhileDragging: true,
                    show: true,
                    showAlternatives: false,
                    addWaypoints: true,
                    fitSelectedRoutes: true,
                }).addTo(map);
            }
        }
    }, [routes]);



    return null;
}

export default withTranslation('common')(Routing);