import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './FigureProject.Component';

type FigureConsumProps = {
    logo?:any,
    description?:string,
    bold?:string,
    order?: any,
}

const mapStateToProps = (state:any,props:FigureConsumProps) => {
    return createStructuredSelector<any,any>({

    });
};

const mapDispatchToProps = {
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);