import React, {useEffect, useRef, useState} from "react";
import {withTranslation} from "react-i18next";
import { Line } from 'react-chartjs-2';

const FigureLineChart:React.FC  = ({logo,backgroundColor,borderColor, circleColor, number,SI, electricity,water,
                                        waste,tre,t}:any) => {

        const [dataValue, setDataValue] = useState([]);
        const [labelValue, setLabelValue] = useState([]);
        useEffect(() => {
                if(SI === 'Wh'){
                        let filterArr = electricity.filter((item:any) => {
                                return item.type === 'JAM' && typeof item.amount !=='undefined'
                        })
                        let labels = electricity.map((item:any) => {
                                return item.id
                        })
                        let result = filterArr.map((item:any) => {
                                return item.amount
                        })
                        setDataValue(result);
                        setLabelValue(labels);
                }
                if(SI === 'l/min'){
                        let filterArr = water.filter((item:any) => {
                                return item.type === 'JAM' && typeof item.amount !=='undefined'
                        })
                        let labels = filterArr.map((item:any) => {
                                return item.id
                        })
                        let result = filterArr.map((item:any) => {
                                return item.amount
                        })
                        setDataValue(result);
                        setLabelValue(labels);
                }
                if(SI === 'kg'){
                        let filterArr = waste.filter((item:any) => {
                                return item.type === 'JAM' && typeof item.amount !=='undefined'
                        })
                        let labels = filterArr.map((item:any) => {
                                return item.id
                        })
                        let result = filterArr.map((item:any) => {
                                return item.amount
                        })
                        setDataValue(result);
                        setLabelValue(labels);
                }
                if(SI === 'co2 kg'){
                        let filterArr = tre.filter((item:any) => {
                                return item.type === 'JAM' && typeof item.amount !=='undefined'
                        })
                        let labels = filterArr.map((item:any) => {
                                return item.id
                        })
                        let result = filterArr.map((item:any) => {
                                return item.amount
                        })
                        setDataValue(result);
                        setLabelValue(labels);
                }


        }, [electricity,water]);

        const data = {
                labels: labelValue,
                datasets: [
                        {
                                data: dataValue,
                                fill: true,
                                backgroundColor: backgroundColor,
                                borderColor: borderColor,
                                tension: 0.5,
                        },
                ],
        };

        const options = {
                plugins:{
                        legend: {
                                display: false
                        },
                        tooltip:{
                                enabled:false
                        }
                },
                elements:{
                        point:{
                                borderWidth:0,
                                pointStyle:''
                        }
                },
                scales: {
                        x: {
                                display: false,
                        },
                        y: {
                                display: true,
                                beginAtZero: true
                        }
                },
                interaction: {
                        intersect: false
                },
                radius: 0,
        };


        return (
            <>
                <div className={"grid grid-cols-3  w-full content-chart bg-background-primary " +
                "rounded-3xl content-center justify-center text-center gap-4 p-4 "}>
                    {
                        logo &&
                            <div className="w-full flex flex-col h-full">
                                <img className="img-chart mb-2 lg:mb-5 justify-center content-center h-16 w-full self-center" src={logo} alt=""/>
                                    <div className={circleColor +" rounded-full w-16 h-16 flex flex-col  flex" +
                                    " items-center justify-center self-center circle-chart"}>
                                            <h3 className="font-black text-xl circle-text">
                                                    {(Math.round(number.JAM * 100) / 100).toFixed(0) }
                                            </h3>
                                            <h4 className="font-black text-sm -mt-2">{SI}</h4>
                                    </div>


                            </div>

                    }
                        <div className="flex flex-col col-span-2 justify-center content-center ml-5 ">
                                <Line data={data} options={options} height={165} width={323}/>
                        </div>
                </div>
            </>
        )

}


export default withTranslation('common')(FigureLineChart);
