import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './Home.Component';
import {getSlides} from "../../redux/reducers/slides";


const mapStateToProps = createStructuredSelector<any,any>({
    slides:getSlides
});

const mapDispatchToProps = {
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);