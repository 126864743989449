import { createSelector } from 'reselect'
import {AppState} from "../store/store";
import {Dispatch} from "redux";
import {CheckIn} from "../../types/route";
import {postAddCheckIn} from "../../services/checkIn";

export const ACTIONS = {
    ADD_CHECKIN_REQUEST: 'ADD_CHECKIN_REQUEST',
    ADD_CHECKIN_SUCCESS: 'ADD_CHECKIN_SUCCESS',
    ADD_CHECKIN_FAILURE: 'ADD_CHECKIN_FAILURE',
    INIT_CHECKIN: 'INIT_CHECKIN',
};

const InitialState = {
    CheckIn : {} as CheckIn,
    inProgress : false,
    isCompleted: false,
    error: ''
}

const getSlice = (state: AppState) => state.checkIn;

export const getIsCompleted = createSelector(
    getSlice,
    ({isCompleted}) => isCompleted,
);

export const getInProgress = createSelector(
    getSlice,
    ({inProgress}) => inProgress,
);


export const setInProgress = (): CheckInActions => ({
    type :ACTIONS.ADD_CHECKIN_REQUEST,
})

export const setInit = (): CheckInActions => ({
    type :ACTIONS.INIT_CHECKIN,
})

export const setCheckInSuccess = (CheckIn:CheckIn): CheckInActions => ({
    type:ACTIONS.ADD_CHECKIN_SUCCESS,
    CheckIn
})

export const setCheckInFailure = (error:string): CheckInActions => ({
    type :ACTIONS.ADD_CHECKIN_FAILURE,
    error
})


export interface CheckInInitiAction {
    type: typeof ACTIONS.INIT_CHECKIN
}
export interface CheckInRequestAction {
    type: typeof ACTIONS.ADD_CHECKIN_REQUEST
}

export interface CheckInSuccessAction {
    type: typeof ACTIONS.ADD_CHECKIN_SUCCESS,
    CheckIn : CheckIn
}

export interface CheckInFailureAction {
    type: typeof ACTIONS.ADD_CHECKIN_FAILURE,
    error : string
}


export type CheckInActions =
    CheckInRequestAction
    | CheckInSuccessAction
    | CheckInFailureAction
    | CheckInInitiAction
    ;


export const addCheckIn = (checkIn:CheckIn) => {
    return async (dispatch: Dispatch<CheckInActions>) => {
        dispatch(setInProgress())
        try {
            const response = await postAddCheckIn(checkIn);
            dispatch(setCheckInSuccess(response.data.response));
            setTimeout(() => {
                dispatch(setInit());
            }, 2000);
        } catch (error:any) {
            dispatch(setCheckInFailure(error))
        }
    }
}

const checkInReducer = (state = InitialState, action: CheckInActions):
    {   inProgress: boolean,
        isCompleted: boolean,
        error: string} => {
    switch (action.type) {
        case ACTIONS.INIT_CHECKIN:
            return {
                ...state,
                isCompleted: false,
            };
        case ACTIONS.ADD_CHECKIN_REQUEST:
            return {
                ...state,
                inProgress : true,
                isCompleted: false,
                error: ''
            };
        case ACTIONS.ADD_CHECKIN_SUCCESS:
            return {
                ...state,
                isCompleted : true,
                inProgress : false,
            };
        case ACTIONS.ADD_CHECKIN_FAILURE:
            return {
                ...state,
                inProgress : false,
                isCompleted : false,
                error: "error" in action ? action.error : ''
            };
        default:
            return state;
    }
};



export {checkInReducer};