import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './Form.Component';
import {retrieveTransportations} from "../../redux/reducers/transportation";
import {getNewRoute, getRoutes, removeRoute} from "../../redux/reducers/route";
import {getLanguages, retrieveLanguages} from "../../redux/reducers/language";
import {addCheckIn, getIsCompleted, getInProgress} from "../../redux/reducers/checkIn";


const mapStateToProps = createStructuredSelector<any,any>({
    routes: getRoutes,
    newRoute: getNewRoute,
    languages: getLanguages,
    checkInIsCompleted: getIsCompleted,
    checkInProgress: getInProgress
});

const mapDispatchToProps = {
    retrieveTransportations,
    retrieveLanguages,
    addCheckIn,
    removeRoute
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);