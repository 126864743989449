import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './ButtonCalcula.Component';

type ButtonCalculaProps = {
    logo?:any
    classTextColor:any
}

const mapStateToProps = (state:any,props:ButtonCalculaProps) => {
    return createStructuredSelector<any,any>({

    });
};

const mapDispatchToProps = {
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);