import React, {useEffect} from "react";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import Form from "./pages/form";
import Thanks from "./pages/thanks";
import Slide from "./pages/slide";
import {useTranslation} from "react-i18next";
import {
    retrieveElectricity,
    retrieveElectricityAvg,
    retrieveTre, retrieveTreAvg, retrieveWaste,
    retrieveWasteAvg, retrieveWater, retrieveWaterAvg
} from "./redux/reducers/consumptionResource";
import {retrieveSlides} from "./redux/reducers/slides";
import {retrieveLanguages} from "./redux/reducers/language";

const Routes = ({   retrieveElectricity,
                    retrieveElectricityAvg,
                    retrieveWater,
                    retrieveWaterAvg,
                    retrieveWaste,
                    retrieveWasteAvg,
                    retrieveTre,
                    retrieveTreAvg,
                    retrieveLanguages,
                    retrieveSlides}:any) => {
    const [t, i18n] = useTranslation('common');

    useEffect(() => {
        const lng = localStorage.getItem('language');
        if(lng){
            i18n.changeLanguage(lng);
        } else {
            i18n.changeLanguage('ca');
        }
        retrieveElectricity();
        retrieveElectricityAvg();
        retrieveWater();
        retrieveWaterAvg();
        retrieveWaste();
        retrieveWasteAvg();
        retrieveTre();
        retrieveTreAvg();
        retrieveSlides();
        retrieveLanguages();
    }, []);

    useEffect(() => {

        const interval = setInterval(() => {
            retrieveElectricity();
            retrieveElectricityAvg();
            retrieveWater();
            retrieveWaterAvg();
            retrieveWaste();
            retrieveWasteAvg();
            retrieveTre();
            retrieveTreAvg();
        } ,12000)
        return () => {
            clearInterval(interval);
        }
    }, [])

    return(
        <Router>
                <Switch>
                    <Route exact path='/' component={Slide} />
                    <Route exact path='/form' component={Form} />
                    <Route exact path='/thanks' component={Thanks} />
                </Switch>
        </Router>
    );
}

const mapStateToProps = createStructuredSelector<any,any>({

});

const mapDispatchToProps = {
    retrieveElectricity,
    retrieveElectricityAvg,
    retrieveWater,
    retrieveWaterAvg,
    retrieveWaste,
    retrieveWasteAvg,
    retrieveTre,
    retrieveTreAvg,
    retrieveSlides,
    retrieveLanguages,
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Routes);