import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './Navigation.Component';
import {getLanguages} from "../../redux/reducers/language";

const mapStateToProps = createStructuredSelector<any,any>({
    languages: getLanguages
});

const mapDispatchToProps = {
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);