import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const Thanks:React.FC  = ({t}:any) => {

    return (
        <>
            <div className="bg-background-img-form bg-cover bg-bottom
            w-full flex flex-col justify-items-center content-center bg-background bg-local">
                <header className="App-header w-full flex flex-col">
                    <div className="w-full flex flex-row justify-center content-center self-center mt-10">
                        <h1 className="font-black text-primary text-center title-form">{t('formTravel.title')}</h1>
                    </div>
                </header>
                <div className="w-full flex flex-col justify-items-center content-center items-center mt-10">
                    <h2>
                        {t('formTravel.thanks')}
                    </h2>
                </div>

                <div className="w-full flex self-end justify-center mt-auto mb-5">
                        <Link to="/">
                            <p className="text-black underline text-2xl	 self-center">
                                {t('formTravel.back')}
                            </p>
                        </Link>

                </div>
            </div>
        </>
    )

}

export default withTranslation('common')(Thanks);