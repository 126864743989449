import { createSelector } from 'reselect'
import {AppState} from "../store/store";
import {Dispatch} from "redux";
import {ConsumptionResource, ConsumptionResourceAVG} from "../../types/consumptionResource";
import {
    getAllElectricity, getAllTravelRelatedEmission, getAllWaste, getAllWater,
    getElectricityAverageByType, getTravelRelatedEmissionAverageByType, getWasteAverageByType,
    getWaterAverageByType
} from "../../services/consumptionResource";
import moment from "moment";

export const ACTIONS = {
    QUERY_ELECTRICITY_REQUEST: 'QUERY_ELECTRICITY_REQUEST',
    QUERY_ELECTRICITY_SUCCESS: 'QUERY_ELECTRICITY_SUCCESS',
    QUERY_ELECTRICITY_FAILURE: 'QUERY_ELECTRICITY_FAILURE',
    QUERY_ELECTRICITYAVERAGE_REQUEST: 'QUERY_ELECTRICITYAVERAGE_REQUEST',
    QUERY_ELECTRICITYAVERAGE_SUCCESS: 'QUERY_ELECTRICITYAVERAGE_SUCCESS',
    QUERY_ELECTRICITYAVERAGE_FAILURE: 'QUERY_ELECTRICITYAVERAGE_FAILURE',
    QUERY_WATER_REQUEST: 'QUERY_WATER_REQUEST',
    QUERY_WATER_SUCCESS: 'QUERY_WATER_SUCCESS',
    QUERY_WATER_FAILURE: 'QUERY_WATER_FAILURE',
    QUERY_WATERAVERAGE_REQUEST: 'QUERY_WATERAVERAGE_REQUEST',
    QUERY_WATERAVERAGE_SUCCESS: 'QUERY_WATERAVERAGE_SUCCESS',
    QUERY_WATERAVERAGE_FAILURE: 'QUERY_WATERAVERAGE_FAILURE',
    QUERY_WASTE_REQUEST: 'QUERY_WASTE_REQUEST',
    QUERY_WASTE_SUCCESS: 'QUERY_WASTE_SUCCESS',
    QUERY_WASTE_FAILURE: 'QUERY_WASTE_FAILURE',
    QUERY_WASTEAVERAGE_REQUEST: 'QUERY_WASTEAVERAGE_REQUEST',
    QUERY_WASTEAVERAGE_SUCCESS: 'QUERY_WASTEAVERAGE_SUCCESS',
    QUERY_WASTEAVERAGE_FAILURE: 'QUERY_WASTEAVERAGE_FAILURE',
    QUERY_TRE_REQUEST: 'QUERY_TRE_REQUEST',
    QUERY_TRE_SUCCESS: 'QUERY_TRE_SUCCESS',
    QUERY_TRE_FAILURE: 'QUERY_TRE_FAILURE',
    QUERY_TREAVERAGE_REQUEST: 'QUERY_TREAVERAGE_REQUEST',
    QUERY_TREAVERAGE_SUCCESS: 'QUERY_TREAVERAGE_SUCCESS',
    QUERY_TREAVERAGE_FAILURE: 'QUERY_TREAVERAGE_FAILURE',
};

const InitialState = {
    electricity : [] as ConsumptionResource[],
    electricityAvg: {} as ConsumptionResourceAVG,
    water: [] as ConsumptionResource[],
    waterAvg: {} as ConsumptionResourceAVG,
    waste : [] as ConsumptionResource[],
    wasteAvg: {} as ConsumptionResourceAVG,
    tre: [] as ConsumptionResource[],
    treAvg: {} as ConsumptionResourceAVG,
    inProgress : false,
    error: ''
}

const getSlice = (state: AppState) => state.consumptionResource;

export const getElectricity = createSelector(
    getSlice,
    ({electricity}) => electricity,
);

export const getElectricityAvg = createSelector(
    getSlice,
    ({electricityAvg}) => electricityAvg,
);

export const getWater = createSelector(
    getSlice,
    ({water}) => water,
);

export const getWaterAvg = createSelector(
    getSlice,
    ({waterAvg}) => waterAvg,
);

export const getWaste = createSelector(
    getSlice,
    ({waste}) => waste,
);

export const getWasteAvg = createSelector(
    getSlice,
    ({wasteAvg}) => wasteAvg,
);

export const getTraveRelatedEmission = createSelector(
    getSlice,
    ({tre}) => tre,
);

export const getTraveRelatedEmissionAvg = createSelector(
    getSlice,
    ({treAvg}) => treAvg,
);

export const setInProgressElectricity = (): ConsumptionResourceActions => ({
    type :ACTIONS.QUERY_ELECTRICITY_REQUEST,
})

export const setElectricitySuccess = (electricity:ConsumptionResource[]): ConsumptionResourceActions => ({
    type:ACTIONS.QUERY_ELECTRICITY_SUCCESS,
    electricity
})

export const setElectricityFailure = (error:string): ConsumptionResourceActions => ({
    type :ACTIONS.QUERY_ELECTRICITY_FAILURE,
    error
})

export const setInProgressElectricityAvg = (): ConsumptionResourceActions => ({
    type :ACTIONS.QUERY_ELECTRICITYAVERAGE_REQUEST,
})

export const setElectricityAvgSuccess = (electricityAvg:ConsumptionResourceAVG): ConsumptionResourceActions => ({
    type:ACTIONS.QUERY_ELECTRICITYAVERAGE_SUCCESS,
    electricityAvg
})

export const setElectricityAvgFailure = (error:string): ConsumptionResourceActions => ({
    type :ACTIONS.QUERY_ELECTRICITYAVERAGE_FAILURE,
    error
})

export interface ElectricityRequestAction {
    type: typeof ACTIONS.QUERY_ELECTRICITY_REQUEST
}

export interface ElectricitySuccessAction {
    type: typeof ACTIONS.QUERY_ELECTRICITY_SUCCESS,
    electricity : ConsumptionResource[]
}

export interface ElectricityFailureAction {
    type: typeof ACTIONS.QUERY_ELECTRICITY_FAILURE,
    error : string
}

export interface ElectricityAvgRequestAction {
    type: typeof ACTIONS.QUERY_ELECTRICITYAVERAGE_FAILURE
}

export interface ElectricityAvgSuccessAction {
    type: typeof ACTIONS.QUERY_ELECTRICITYAVERAGE_SUCCESS,
    electricityAvg : ConsumptionResourceAVG
}

export interface ElectricityAvgFailureAction {
    type: typeof ACTIONS.QUERY_ELECTRICITYAVERAGE_FAILURE,
    error : string
}


export const setInProgressWater = (): ConsumptionResourceActions => ({
    type :ACTIONS.QUERY_WATER_REQUEST,
})

export const setWaterSuccess = (water:ConsumptionResource[]): ConsumptionResourceActions => ({
    type:ACTIONS.QUERY_WATER_SUCCESS,
    water
})

export const setWaterFailure = (error:string): ConsumptionResourceActions => ({
    type :ACTIONS.QUERY_WATER_FAILURE,
    error
})

export const setInProgressWaterAvg = (): ConsumptionResourceActions => ({
    type :ACTIONS.QUERY_WATERAVERAGE_REQUEST,
})

export const setWaterAvgSuccess = (waterAvg:ConsumptionResourceAVG): ConsumptionResourceActions => ({
    type:ACTIONS.QUERY_WATERAVERAGE_SUCCESS,
    waterAvg
})

export const setWaterAvgFailure = (error:string): ConsumptionResourceActions => ({
    type :ACTIONS.QUERY_WATERAVERAGE_FAILURE,
    error
})

export interface WaterRequestAction {
    type: typeof ACTIONS.QUERY_WATER_REQUEST
}

export interface WaterSuccessAction {
    type: typeof ACTIONS.QUERY_WATER_SUCCESS,
    water : ConsumptionResource[]
}

export interface WaterFailureAction {
    type: typeof ACTIONS.QUERY_WATER_FAILURE,
    error : string
}

export interface WaterAvgRequestAction {
    type: typeof ACTIONS.QUERY_WATERAVERAGE_FAILURE
}

export interface WaterAvgSuccessAction {
    type: typeof ACTIONS.QUERY_WATERAVERAGE_SUCCESS,
    waterAvg : ConsumptionResourceAVG
}

export interface WaterAvgFailureAction {
    type: typeof ACTIONS.QUERY_WATERAVERAGE_FAILURE,
    error : string
}


export const setInProgressWaste = (): ConsumptionResourceActions => ({
    type :ACTIONS.QUERY_WASTE_REQUEST,
})

export const setWasteSuccess = (waste:ConsumptionResource[]): ConsumptionResourceActions => ({
    type:ACTIONS.QUERY_WASTE_SUCCESS,
    waste
})

export const setWasteFailure = (error:string): ConsumptionResourceActions => ({
    type :ACTIONS.QUERY_WASTE_FAILURE,
    error
})

export const setInProgressWasteAvg = (): ConsumptionResourceActions => ({
    type :ACTIONS.QUERY_WASTEAVERAGE_REQUEST,
})

export const setWasteAvgSuccess = (wasteAvg:ConsumptionResourceAVG): ConsumptionResourceActions => ({
    type:ACTIONS.QUERY_WASTEAVERAGE_SUCCESS,
    wasteAvg
})

export const setWasteAvgFailure = (error:string): ConsumptionResourceActions => ({
    type :ACTIONS.QUERY_WASTEAVERAGE_FAILURE,
    error
})

export interface WasteRequestAction {
    type: typeof ACTIONS.QUERY_WASTE_REQUEST
}

export interface WasteSuccessAction {
    type: typeof ACTIONS.QUERY_WASTE_SUCCESS,
    waste : ConsumptionResource[]
}

export interface WasteFailureAction {
    type: typeof ACTIONS.QUERY_WASTE_FAILURE,
    error : string
}

export interface WasteAvgRequestAction {
    type: typeof ACTIONS.QUERY_WASTEAVERAGE_FAILURE
}

export interface WasteAvgSuccessAction {
    type: typeof ACTIONS.QUERY_WASTEAVERAGE_SUCCESS,
    wasteAvg : ConsumptionResourceAVG
}

export interface WasteAvgFailureAction {
    type: typeof ACTIONS.QUERY_WASTEAVERAGE_FAILURE,
    error : string
}


export const setInProgressTre = (): ConsumptionResourceActions => ({
    type :ACTIONS.QUERY_TRE_REQUEST,
})

export const setTreSuccess = (tre:ConsumptionResource[]): ConsumptionResourceActions => ({
    type:ACTIONS.QUERY_TRE_SUCCESS,
    tre
})

export const setTreFailure = (error:string): ConsumptionResourceActions => ({
    type :ACTIONS.QUERY_TRE_FAILURE,
    error
})

export const setInProgressTreAvg = (): ConsumptionResourceActions => ({
    type :ACTIONS.QUERY_TREAVERAGE_REQUEST,
})

export const setTreAvgSuccess = (treAvg:ConsumptionResourceAVG): ConsumptionResourceActions => ({
    type:ACTIONS.QUERY_TREAVERAGE_SUCCESS,
    treAvg
})

export const setTreAvgFailure = (error:string): ConsumptionResourceActions => ({
    type :ACTIONS.QUERY_TREAVERAGE_FAILURE,
    error
})

export interface TreRequestAction {
    type: typeof ACTIONS.QUERY_TRE_REQUEST
}

export interface TreSuccessAction {
    type: typeof ACTIONS.QUERY_TRE_SUCCESS,
    tre : ConsumptionResource[]
}

export interface TreFailureAction {
    type: typeof ACTIONS.QUERY_TRE_FAILURE,
    error : string
}

export interface TreAvgRequestAction {
    type: typeof ACTIONS.QUERY_TREAVERAGE_FAILURE
}

export interface TreAvgSuccessAction {
    type: typeof ACTIONS.QUERY_TREAVERAGE_SUCCESS,
    treAvg : ConsumptionResourceAVG
}

export interface TreAvgFailureAction {
    type: typeof ACTIONS.QUERY_TREAVERAGE_FAILURE,
    error : string
}

export type ConsumptionResourceActions =
    ElectricityRequestAction
    | ElectricitySuccessAction
    | ElectricityFailureAction
    | ElectricityAvgRequestAction
    | ElectricityAvgSuccessAction
    | ElectricityAvgFailureAction
    | WaterRequestAction
    | WaterSuccessAction
    | WaterFailureAction
    | WaterAvgRequestAction
    | WaterAvgSuccessAction
    | WaterAvgFailureAction
    | WasteRequestAction
    | WasteSuccessAction
    | WasteFailureAction
    | WasteAvgRequestAction
    | WasteAvgSuccessAction
    | WasteAvgFailureAction
    | TreRequestAction
    | TreSuccessAction
    | TreFailureAction
    | TreAvgRequestAction
    | TreAvgSuccessAction
    | TreAvgFailureAction
    ;

export const retrieveElectricity = () => {
    return async (dispatch: Dispatch<ConsumptionResourceActions>) => {
        dispatch(setInProgressElectricity())
        try {
            const start = moment.utc().subtract(1,"day").format("DD/MM/YYYY%20HH:mm");
            const end = moment.utc().format("DD/MM/YYYY%20HH:mm");
            const response = await getAllElectricity(start,end);
            dispatch(setElectricitySuccess(response.data.response));
        } catch (error:any) {
            dispatch(setElectricityFailure(error))
        }
    }
}

export const retrieveElectricityAvg = () => {
    return async (dispatch: Dispatch<ConsumptionResourceActions>) => {
        dispatch(setInProgressElectricityAvg())
        try {
            const start = moment.utc().subtract(1,"day").format("DD/MM/YYYY%20HH:mm");
            const end = moment.utc().format("DD/MM/YYYY%20HH:mm");
            const response = await getElectricityAverageByType(start,end);
            dispatch(setElectricityAvgSuccess(response.data.response));
        } catch (error:any) {
            dispatch(setElectricityAvgFailure(error))
        }
    }
}

export const retrieveWater = () => {
    return async (dispatch: Dispatch<ConsumptionResourceActions>) => {
        dispatch(setInProgressWater())
        try {
            const start = moment.utc().subtract(1,"day").format("DD/MM/YYYY%20HH:mm");
            const end = moment.utc().format("DD/MM/YYYY%20HH:mm");
            const response = await getAllWater(start,end);
            dispatch(setWaterSuccess(response.data.response));
        } catch (error:any) {
            dispatch(setWaterFailure(error))
        }
    }
}

export const retrieveWaterAvg = () => {
    return async (dispatch: Dispatch<ConsumptionResourceActions>) => {
        dispatch(setInProgressWaterAvg())
        try {
            const start = moment.utc().subtract(1,"day").format("DD/MM/YYYY%20HH:mm");
            const end = moment.utc().format("DD/MM/YYYY%20HH:mm");
            const response = await getWaterAverageByType(start,end);
            dispatch(setWaterAvgSuccess(response.data.response));
        } catch (error:any) {
            dispatch(setWaterAvgFailure(error))
        }
    }
}

export const retrieveWaste = () => {
    return async (dispatch: Dispatch<ConsumptionResourceActions>) => {
        dispatch(setInProgressWaste())
        try {
            const start = moment.utc().subtract(7,"day").format("DD/MM/YYYY%20HH:mm");
            const end = moment.utc().format("DD/MM/YYYY%20HH:mm");
            const response = await getAllWaste(start,end);
            dispatch(setWasteSuccess(response.data.response));
        } catch (error:any) {
            dispatch(setWasteFailure(error))
        }
    }
}

export const retrieveWasteAvg = () => {
    return async (dispatch: Dispatch<ConsumptionResourceActions>) => {
        dispatch(setInProgressWasteAvg())
        try {
            const start = moment.utc().subtract(7,"day").format("DD/MM/YYYY%20HH:mm");
            const end = moment.utc().format("DD/MM/YYYY%20HH:mm");
            const response = await getWasteAverageByType(start,end);
            dispatch(setWasteAvgSuccess(response.data.response));
        } catch (error:any) {
            dispatch(setWasteAvgFailure(error))
        }
    }
}

export const retrieveTre = () => {
    return async (dispatch: Dispatch<ConsumptionResourceActions>) => {
        dispatch(setInProgressTre())
        try {
            const response = await getAllTravelRelatedEmission(7);
            dispatch(setTreSuccess(response.data.response));
        } catch (error:any) {
            dispatch(setTreFailure(error))
        }
    }
}

export const retrieveTreAvg = () => {
    return async (dispatch: Dispatch<ConsumptionResourceActions>) => {
        dispatch(setInProgressTreAvg())
        try {
            const response = await getTravelRelatedEmissionAverageByType(7);
            dispatch(setTreAvgSuccess(response.data.response));
        } catch (error:any) {
            dispatch(setTreAvgFailure(error))
        }
    }
}

const consumptionResourceReducer = (state = InitialState, action: ConsumptionResourceActions):
    {   inProgress: boolean,
        error: string,
        electricityAvg: {},
        electricity:ConsumptionResource[],
        waterAvg: {},
        water:ConsumptionResource[],
        wasteAvg: {},
        waste:ConsumptionResource[],
        treAvg: {},
        tre:ConsumptionResource[],
    } => {
    switch (action.type) {
        case ACTIONS.QUERY_ELECTRICITY_REQUEST:
        case ACTIONS.QUERY_ELECTRICITYAVERAGE_REQUEST:
        case ACTIONS.QUERY_WATER_REQUEST:
        case ACTIONS.QUERY_WATERAVERAGE_REQUEST:
        case ACTIONS.QUERY_WASTE_REQUEST:
        case ACTIONS.QUERY_WASTEAVERAGE_REQUEST:
        case ACTIONS.QUERY_TRE_REQUEST:
        case ACTIONS.QUERY_TREAVERAGE_REQUEST:
            return {
                ...state,
                inProgress : true,
                error: ''
            };
        case ACTIONS.QUERY_ELECTRICITY_SUCCESS:
            return {
                ...state,
                inProgress : false,
                electricity: "electricity" in action ? action.electricity : []
            };
        case ACTIONS.QUERY_ELECTRICITYAVERAGE_SUCCESS:
            return {
                ...state,
                inProgress : false,
                electricityAvg: "electricityAvg" in action ? action.electricityAvg : {}
            };
        case ACTIONS.QUERY_WATER_SUCCESS:
            return {
                ...state,
                inProgress : false,
                water: "water" in action ? action.water : []
            };
        case ACTIONS.QUERY_WATERAVERAGE_SUCCESS:
            return {
                ...state,
                inProgress : false,
                waterAvg: "waterAvg" in action ? action.waterAvg : {}
            };
        case ACTIONS.QUERY_WASTE_SUCCESS:
            return {
                ...state,
                inProgress : false,
                waste: "waste" in action ? action.waste : []
            };
        case ACTIONS.QUERY_WASTEAVERAGE_SUCCESS:
            return {
                ...state,
                inProgress : false,
                wasteAvg: "wasteAvg" in action ? action.wasteAvg : {}
            };
        case ACTIONS.QUERY_TRE_SUCCESS:
            return {
                ...state,
                inProgress : false,
                tre: "tre" in action ? action.tre : []
            };
        case ACTIONS.QUERY_TREAVERAGE_SUCCESS:
            return {
                ...state,
                inProgress : false,
                treAvg: "treAvg" in action ? action.treAvg : {}
            };
        case ACTIONS.QUERY_ELECTRICITY_FAILURE:
        case ACTIONS.QUERY_ELECTRICITYAVERAGE_FAILURE:
        case ACTIONS.QUERY_WATER_FAILURE:
        case ACTIONS.QUERY_WATERAVERAGE_FAILURE:
        case ACTIONS.QUERY_WASTE_FAILURE:
        case ACTIONS.QUERY_WASTEAVERAGE_FAILURE:
        case ACTIONS.QUERY_TRE_FAILURE:
        case ACTIONS.QUERY_TREAVERAGE_FAILURE:
            return {
                ...state,
                inProgress : false,
                error: "error" in action ? action.error : ''
            };
        default:
            return state;
    }
};



export {consumptionResourceReducer};