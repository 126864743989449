import React from "react";
import {withTranslation} from "react-i18next";
import line from "../../assets/images/line.png";

const FigureProject:React.FC  = ({logo,description, order, bold , t}:any) => {


        return (
            <>
                <div className={"grid grid-cols-2 w-full content-project bg-background-primary " +
                "rounded-3xl content-center self-center justify-center text-center gap-2 2xl:gap-0 p-2 lg:p-4 w-full " +
                "2xl:mb-auto "}>
                    {
                        logo && order !== 'change' &&
                            <div className="w-full flex p-0 2xl:p-4 self-center justify-center items-center ">
                                <img className="img-project  w-full justify-center rounded-full border-0 content-center self-center" src={logo} alt=""/>
                            </div>

                    }
                    {
                        description &&
                            <div className="flex flex-col justify-center h-full self-center lg:self-start content-center">
                                <p className="text-center lg:self-start mt-10 mb-5" >
                                    <div className={"text-xl"} dangerouslySetInnerHTML={{__html: bold}}></div>
                                    <div dangerouslySetInnerHTML={{__html: description}}></div>
                                </p>
                                <img src={line} className="self-center lg:self-end mt-auto" alt=""/>
                            </div>

                    }
                        {
                                logo && order === 'change' &&
                                <div className="w-full flex p-4 justify-center ">
                                        <img className="img-project  w-full justify-center rounded-full border-0 content-center self-center" src={logo} alt=""/>
                                </div>

                        }


                </div>
            </>
        )

}


export default withTranslation('common')(FigureProject);
