import api from "./api";


export async function getAllTransportations() {
    // const headers: any = {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept-Language': language,
    //     },
    // };

    const response = await api.get("/transportations");
    return response;

}
