import { createSelector } from 'reselect'
import {AppState} from "../store/store";
import {Dispatch} from "redux";
import {Transportation} from "../../types/transportation";
import {getAllTransportations} from "../../services/transportation";

export const ACTIONS = {
    QUERY_TRANSPORTATION_REQUEST: 'QUERY_TRANSPORTATION_REQUEST',
    QUERY_TRANSPORTATION_SUCCESS: 'QUERY_TRANSPORTATION_SUCCESS',
    QUERY_TRANSPORTATION_FAILURE: 'QUERY_TRANSPORTATION_FAILURE',
};

const InitialState = {
    transportations : [] as Transportation[],
    inProgress : false,
    error: ''
}

const getSlice = (state: AppState) => state.transportations;

export const getTransportations = createSelector(
    getSlice,
    ({transportations}) => transportations,
);

export const getNewTransportations = createSelector(
    getSlice,
    ({inProgress}) => inProgress,
);

export const setInProgress = (): TransportationsActions => ({
    type :ACTIONS.QUERY_TRANSPORTATION_REQUEST,
})

export const setTransportationsSuccess = (transportations:Transportation[]): TransportationsActions => ({
    type:ACTIONS.QUERY_TRANSPORTATION_SUCCESS,
    transportations
})

export const setTransportationsFailure = (error:string): TransportationsActions => ({
    type :ACTIONS.QUERY_TRANSPORTATION_FAILURE,
    error
})


export interface TransportationRequestAction {
    type: typeof ACTIONS.QUERY_TRANSPORTATION_REQUEST
}

export interface TransportationSuccessAction {
    type: typeof ACTIONS.QUERY_TRANSPORTATION_SUCCESS,
    transportations : Transportation[]
}

export interface TransportationFailureAction {
    type: typeof ACTIONS.QUERY_TRANSPORTATION_FAILURE,
    error : string
}


export type TransportationsActions =
    TransportationRequestAction
    | TransportationSuccessAction
    | TransportationFailureAction
    ;


export const retrieveTransportations = () => {
    return async (dispatch: Dispatch<TransportationsActions>) => {
        dispatch(setInProgress())
        try {
            const response = await getAllTransportations();
            dispatch(setTransportationsSuccess(response.data.response));
        } catch (error:any) {
            dispatch(setTransportationsFailure(error))
        }
    }
}

const transportationsReducer = (state = InitialState, action: TransportationsActions):
    {   inProgress: boolean,
        error: string,
        transportations:Transportation[], } => {
    switch (action.type) {
        case ACTIONS.QUERY_TRANSPORTATION_REQUEST:
            return {
                ...state,
                inProgress : true,
                error: ''
            };
        case ACTIONS.QUERY_TRANSPORTATION_SUCCESS:
            return {
                ...state,
                inProgress : false,
                transportations: "transportations" in action ? action.transportations : []
            };
        case ACTIONS.QUERY_TRANSPORTATION_FAILURE:
            return {
                ...state,
                inProgress : false,
                error: "error" in action ? action.error : ''
            };
        default:
            return state;
    }
};



export {transportationsReducer};