import React from "react";
import {withTranslation} from "react-i18next";


const Header:React.FC  = ({title,subtitle,t}:any) => {


        return (
            <>
                <header className="App-header w-full flex-none flex flex-col justifiy-center justify-items-center content-center item-center">
                    <h1 className="title-header text-center uppercase font-black mt-4">{title}</h1>
                    { subtitle &&
                        <p className="mt-2 text-center text-xl m-auto ">{subtitle}</p>
                    }

                </header>
            </>
        )

}


export default withTranslation('common')(Header);
