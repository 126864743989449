import React, {useEffect, useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import lamp from "../../assets/images/lamp.png";
import trash from "../../assets/images/trash.png";
import airplane from "../../assets/images/airplane.png";
import water from "../../assets/images/water.png";
import FigureConsum from "../../components/figureConsum";
import ButtonCalcula from "../../components/buttonCalcula";
import logo from "../../assets/images/logo-light.png";
import Header from "../../components/header";
import {getText} from "../../services/functions";

const Comparation:React.FC  = ({electricityAvg,waterAvg,wasteAvg,treAvg,slides,}:any) => {

    const [t, i18n] = useTranslation('common');
    const [title, setTitle] = useState('');
    const [bold, setBold] = useState('');
    const [regular, setRegular] = useState('');
    const [subTitle, setSubTitle] = useState('');

    useEffect(() => {
        getText(slides, 2, i18n, setTitle, setBold, setRegular,setSubTitle);
    }, [slides,t]);


    return (
        <>
            <div className="w-full h-screen flex flex-col justify-items-center content-center bg-background bg-local">
                <Header title={title}
                        subtitle={subTitle}  />

                <div className="flex-auto  grid grid-cols-1 grid-cols-4 lg:gap-5 p-2 lg:p-4 justify-items-center content-center mt-auto
                                self-center gap-2 w-full 2xl:w-full 2xl:mb-auto ">
                    {/*Electricity*/}
                    <FigureConsum logo={lamp} SI="Wh" numberJam={electricityAvg.JAM} numberBcn={electricityAvg.BCN}/>
                    {/*Water*/}
                    <FigureConsum logo={water} SI="l/min" numberJam={waterAvg.JAM} numberBcn={waterAvg.BCN}/>
                    {/*Waste*/}
                    <FigureConsum logo={trash} SI="Kg" numberJam={wasteAvg.JAM} numberBcn={wasteAvg.BCN}/>
                    {/*Airplane*/}
                    <FigureConsum logo={airplane} SI=" co2 kg" numberJam={treAvg.JAM} numberBcn={treAvg.BCN}/>
                </div>
                <ButtonCalcula logo={logo} classTextColor="text-secondary" />
            </div>
        </>
    )

}

export default withTranslation('common')(Comparation);
