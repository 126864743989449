import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {Route} from "../../types/route";
import {Transportation} from "../../types/transportation";
import * as Nominatim from 'nominatim-browser';
import Geolookup from 'react-geolookup-v2';

const FormRoute: React.FC = ({id, addRoute, transportations, routes, origin, t}: any) => {
    const initRoute: Route = {
        transportation: {id: 0},
        destination: {name: '', latitude: '0', longitude: '0'},
        origin: {
            name: '',
            latitude: '0',
            longitude: '0'
        }
    };
    if (typeof origin.latitude !== 'undefined') {
        initRoute.origin = origin;
    }
    const [route, setRoute] = useState(initRoute);
    const colspan = id > 1 ? 'col-span-2' : 'flex-wrap -mx-3';
    const onSuggestsLookup = (userInput: any) => {
        return Nominatim.geocode({
            q: userInput,
            addressdetails: true
        });
    }
    console.log(transportations);
    const onGeocodeSuggest = (type: string, id: number, suggest: any) => {
        let geocoded: any = {};
        if (suggest) {
            geocoded.nominatim = suggest.raw || {};
            geocoded.location = {
                lat: suggest.raw ? suggest.raw.lat : '',
                lon: suggest.raw ? suggest.raw.lon : ''
            };
            geocoded.placeId = suggest.placeId;
            geocoded.isFixture = suggest.isFixture;
            geocoded.label = suggest.raw ? suggest.raw.display_name : '';
            let routeData: Route = route;
            routeData.id = id - 1;
            if (type === 'origin') {
                routeData.origin.longitude = geocoded.location.lat;
                routeData.origin.latitude = geocoded.location.lon;
                routeData.origin.name = geocoded.label;
            }
            if (type === 'destination') {
                routeData.destination.longitude = geocoded.location.lat;
                routeData.destination.latitude = geocoded.location.lon;
                routeData.destination.name = geocoded.label;
            }
            if (route.destination.longitude.length > 1 && route.origin.longitude.length > 1) {
                addRoute(route)
            }
            setRoute(routeData);
        }

        return geocoded;
    }

    const getSuggestLabel = (suggest: any) => {
        return suggest.display_name;
    }
    return (
        <>
            {
                id === 1 &&
                <div className="flex ">
                    <div className="w-full md:px-3 md:mb-6 md:mb-0">
                        <Geolookup
                            inputClassName="geolookup__input--nominatim"
                            disableAutoLookup={false}
                            suggestsHiddenClassName={"hidden"}
                            required={true}
                            country=".es"
                            placeholder={t('formRoute.origin')}
                            onSuggestsLookup={onSuggestsLookup}
                            onGeocodeSuggest={(result: any) => onGeocodeSuggest('origin', id, result)}
                            getSuggestLabel={getSuggestLabel}
                            radius="20"/>
                    </div>
                </div>
            }

            <div className={"flex  " + colspan}>
                <div className="w-full px-3 md:mb-6 md:mb-0">
                    <Geolookup
                        ignoreEnter={true}
                        inputClassName="geolookup__input--nominatim"
                        disableAutoLookup={false}
                        suggestsHiddenClassName={"hidden"}
                        country={"es"}
                        required={true}
                        onSuggestsLookup={onSuggestsLookup}
                        placeholder={t('formRoute.destination')}
                        onGeocodeSuggest={(result: any) => onGeocodeSuggest('destination', id, result)}
                        getSuggestLabel={getSuggestLabel}
                        radius="20"/>
                </div>
            </div>
            <div className="">
                <div className="relative">
                    <select
                        className="block appearance-none
                                        w-full  py-3.5 px-4 pr-8
                                        rounded-xl leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-state"

                        onChange={(e) => {
                            e.preventDefault();
                            let routeData = route;
                            route.transportation = {
                                id: parseInt(e.target.value)
                            };
                            setRoute(routeData);
                            if (route.destination.longitude.length > 1 && route.origin.longitude.length > 1) {
                                addRoute(route)
                            }
                        }}
                    >
                        <option value="0">{t("formTravel.transport")}</option>
                        {transportations.map((transport: Transportation, key: number) => {
                            return <option key={key + "_transport_" + id}
                                           value={transport.id}>{t("formTravel." + transport.name)}</option>
                        })}
                            </select>
                            <div
                            className="pointer-events-none absolute inset-y-0 right-0 flex items-center py-0 px-2 text-gray-700">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20">
                            <path
                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                            </svg>
                            </div>
                            </div>
                            </div>
                            </>
                            )

                        }


                        export default withTranslation('common')(FormRoute);
